/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {


        //OnScroll
        $(window).on('scroll', function () {

        });//end on Scroll


        //Mobile nav menu click
        $(document).on('click', 'div.hamburger', function () {
          $(this).toggleClass('is-active');
          $('div.navbar-collapse').toggleClass('collapse');
          $('header.header').toggleClass('active');
        });


        //Close the mobile menu on body click
        $(document).on('click', 'body > main', function () {
          if ($('div.hamburger').hasClass('is-active')) {
            $('div.hamburger').trigger('click');
          }
        });


        //Page scroll
        $("a.page-scroll").bind("click", function (e) {
          var offset = 75,
              t = $(this);
          $("html, body").stop().animate({
            scrollTop: (parseInt($(t.attr("href")).offset().top) - offset)
          }, 1500, "easeInOutExpo");
          e.preventDefault();
        });


        //Header search click
        $('span.header-search').click(function(){
          $(this).toggleClass('active');
          $('div.mc-search').toggleClass('active');
        });

        //Header search close
        $('span.header-search-close').click(function(){
          $('div.mc-search').removeClass('active');
          $('span.header-search').removeClass('active');
        });


        //Assets filter Isotope
        $(window).load(function () {

          // if ($('.isotope-wrapper').length) {
          //   var $container = $('.isotope-wrapper');
          //   $container.isotope({
          //     itemSelector: '.isotope-item',
          //     layoutMode: 'masonry',
          //     getSortData: {
          //       post_date: '.asset-ts'
          //     }
          //   });
          // }

        });



        //Tags select onChange
        $('select#assets-tags-select').on('change', function () {

          if ($('.isotope-wrapper').length) {

            var val = $(this).val();
            if (val !== '*') {
              val = '.' + val;
            }

            //Check if the other filter is set and concat values
            if ($('select#assets-type-select').val() !== '*') {
              val += '.'+$('select#assets-type-select').val();
            }

            $('.isotope-wrapper').isotope({filter: val});

            if (!$('.isotope-wrapper').data('isotope').filteredItems.length) {
              $('div.isotope-no-results').addClass('active');
            } else {
              $('div.isotope-no-results').removeClass('active');
            }
          }
        });


        //Type select onChange
        $('select#assets-type-select').on('change', function () {

          if ($('.isotope-wrapper').length) {

            var val = $(this).val();
            if (val !== '*') {
              val = '.' + val;
            }

            //Check if the other filter is set and concat values
            if ($('select#assets-tags-select').val() !== '*') {
              val += '.'+$('select#assets-tags-select').val();
            }

            $('.isotope-wrapper').isotope({filter: val});

            if (!$('.isotope-wrapper').data('isotope').filteredItems.length) {
              $('div.isotope-no-results').addClass('active');
            } else {
              $('div.isotope-no-results').removeClass('active');
            }
          }
        });





        //Check for filter-by data-attr and filter on page load if set
        if ($('div.isotope-wrapper').length && $('div.isotope-wrapper').data('filter-by') !== undefined && $('div.isotope-wrapper').data('filter-by') !== '') {
          var val = $('div.isotope-wrapper').data('filter-by');

          if (val === 'all') {
            val = '*';
            $('select#assets-type-select').val(val);
          } else {
            $('select#assets-type-select').val(val);

            $('.isotope-wrapper').isotope({filter: '.'+val});

            if (!$('.isotope-wrapper').data('isotope').filteredItems.length) {
              $('div.isotope-no-results').addClass('active');
            } else {
              $('div.isotope-no-results').removeClass('active');
            }
          }

        }





        //Assets order onChange
        /*if ($('select#assets-order').length) {
          $('select#assets-order').change(function(){
            var order = ($(this).val() === 'asc') ? 'post_date' : 'original-order';
            $('.isotope-wrapper').isotope({sortBy: order});
          });
        }*/


        //Social share toggle click
        $(document).on('click', 'span.social-toggle', function(){
          $(this).toggleClass('active');
          $(this).next('.social-wrap').toggleClass('active');
        });


        //Filter bar sticky
        if ($('#assets-filter').length) {
          var os = $('header.header').outerHeight();
          $('#assets-filter').stick_in_parent({
            offset_top: os
          });
        }


        //Delay init of sticky until asset img is fully loaded
        if ($('img.media-asset-img').length) {
          imagesLoaded('img.media-asset-img', {background: false}, function () {
            //Social sticky
            if ($('div.social-outer').length) {
              if (window.innerWidth >= 768) {
                $('div.social-outer').stick_in_parent({
                  parent: $("#social-container"),
                  offset_top: 120,
                  bottoming: true,
                  recalc_every: 1
                });
              }
            }
          });
        } else {
          //Social sticky
          if ($('div.social-outer').length) {
            if (window.innerWidth >= 768) {
              $('div.social-outer').stick_in_parent({
                parent: $("#social-container"),
                offset_top: 120,
                bottoming: true,
                recalc_every: 1
              });
            }
          }
        }


        $('.tags-toggle').click(function(){
          $(this).find('span.icon-wrap').toggleClass('active');
          $('div.all-tags-outer').toggleClass('active');
        });



        //Video popups
        if ($('.video-lightbox').length) {
          $('.video-lightbox').magnificPopup({
            type: 'iframe',
            preloader: true,
            removalDelay: 500, //delay removal by X to allow out-animation
            callbacks: {
              beforeOpen: function () {
                // just a hack that adds mfp-anim class to markup
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                this.st.mainClass = this.st.el.attr('data-effect');
              }
            },
            closeOnContentClick: true
          });//end video modal
        }


        //Image popups
        // $('.magnific-gallery').each(function () {
        //   $(this).magnificPopup({
        //     delegate: 'a',
        //     type: 'image',
        //     preloader: true,
        //     gallery: {
        //       enabled: true
        //     },
        //     removalDelay: 500, //delay removal by X to allow out-animation
        //     callbacks: {
        //       beforeOpen: function () {
        //         // just a hack that adds mfp-anim class to markup
        //         this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
        //         this.st.mainClass = this.st.el.attr('data-effect');
        //       }
        //     },
        //     closeOnContentClick: true,
        //     midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
        //   });
        // });//end image modal



        //Frontpage carousel
        if ($('div.frontpage-carousel').length) {

          $('div.frontpage-carousel').owlCarousel({

            items: 1,
            dots: true,
            nav: false,
            autoplay: true,
            autoplaySpeed: 2400,
            animateOut: 'fadeOut',
            loop: true

          });

        }//end frontpage carousel



        //Assets carousel
        $('.assets-carousel').owlCarousel({
          items: 5,
          center: true,
          loop: true,
          dots: false,
          nav: true,
          navText: ['<i class="ion-chevron-left"></i>', '<i class="ion-chevron-right"></i>'],
          responsiveClass:true,
          margin: 15,
          responsive: {
            0: {
              items: 1,
              margin: 0
            },
            768: {
              items: 2,
              margin: 0
            },
            1024: {
              items: 3,
              margin: 0
            },
            1025: {
              items: 4,
              margin: 15
            },
            1600: {
              items: 5,
              margin: 15
            }
          }
        });//end img carousel

        var news_carousel = $('.news-carousel');
        news_carousel.children().each(function (index) {
          $(this).attr('data-position', index);
        });

        news_carousel.owlCarousel({
          items: 4,
          center: true,
          loop: true,
          dots: false,
          nav: true,
          navText: ['<i class="ion-chevron-left"></i>', '<i class="ion-chevron-right"></i>'],
          responsiveClass:true,
          margin: 15,
          responsive: {
            0: {
              items: 1,
              margin: 0
            },
            768: {
              items: 2,
              margin: 0
            },
            1024: {
              items: 2,
              margin: 0
            },
            1025: {
              items: 3,
              margin: 15
            },
            1600: {
              items: 4,
              margin: 15
            }
          }
        });//end img carousel
        news_carousel.find('.owl-item > div').click(function(){
          news_carousel.trigger('to.owl.carousel', $(this).data('position'));
        });



        $('.logos-carousel').owlCarousel({
          items: 5,
          center: true,
          loop: true,
          dots: true,
          responsiveClass:true,
          responsive: {
            0: {
              items: 2,
              margin: 0
            },
            768: {
              items: 3,
              margin: 0
            },
            1024: {
              items: 4,
              margin: 0
            },
            1025: {
              items: 5,
              margin: 15
            },
            1600: {
              items: 5,
              margin: 15
            }
          }
        });//end img carousel



        $('.logos-carousel-2').owlCarousel({
          items: 1,
          center: true,
          loop: false,
          dots: true,
          responsiveClass:true,
          responsive: {
            0: {
              items: 1,
              margin: 0
            },
            768: {
              items: 1,
              margin: 0
            },
            1024: {
              items: 1,
              margin: 0
            },
            1025: {
              items: 1,
              margin: 0
            },
            1600: {
              items: 1,
              margin: 0
            }
          }
        });//end img carousel

        /*$('.tags-carousel').owlCarousel({
          items: 4,
          center: true,
          loop: true,
          dots: false,
          nav: true,
          navText: ['<i class="ion-chevron-left"></i>', '<i class="ion-chevron-right"></i>'],
          responsiveClass: true,
          margin: 5,
          responsive: {
            0: {
              items: 2,
            },
            769: {
              items: 3,
            },
            1024: {
              items: 3,
            },
            1025: {
              items: 4,
            }
          }
        });*///end img carousel


        $('.styled-select').select2({
          minimumResultsForSearch: Infinity
        });


        /**
         * Click the assets filter search button - add topic and type to URL
         */
        $('a.all-tags-filter-search').click(function(e){

          e.preventDefault();

          var tag = $('select#assets-tags-select').val();
          var type = $('select#assets-type-select').val();

          if (tag === 'all' && type === 'all') {
            window.location = '/content';
            return false;
          }

          if (tag === 'all') {
            window.location = '/content/?type='+type;
            return false;
          }

          window.location = tag+'?type='+type;
          return false;

        });



        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
